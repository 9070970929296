/* You can add global styles to this file, and also import other style files */

html, body {
  height: 100%;
  min-height: 100vh
}
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

footer.mainfooter {
  width: 100%;
  flex: 0 0 auto;
}

@media(max-width:767px)
{
footer.mainfooter {
  padding-bottom: 40px;
}
}

.container-main {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.body {
  flex: 1;
}

